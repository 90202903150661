.main{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
}
.main h3{
    margin: 0;
    padding: 16px;
}
.main h1{
    margin: 0;
}
.links{
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 630px;
}

.titles{
    margin-top: 10%;
    color: white;
    width: 630px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


@media (max-width:850px){
    .titles{
        margin-top: 250px;
    }
}
@media (max-width:1100px){
    .main{
        justify-content: center;
    }
}
@media (min-width:1900px){
    .main{
        justify-content: center;
    }
}

@media (max-width:550px){
    .titles h3{
        font-size: 1em;
    }
    .titles h1{
        font-size: 1.5em;
    }
}

@media (max-width:700px){
    .titles{
        width: 100%;
    }
    .links{
        width: 100%;
    }
}



