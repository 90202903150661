.main {
    height: 100%;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: white;
}

.main h3 {
    margin: 0;
    padding: 16px;
}

.main a:link {
    color: wheat;
}

.main h1 {
    margin: 0;
}
