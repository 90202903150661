.link{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 70px;
    margin: 10px;
}
.link img {
    width: 100%;
}
.gpImg{
    border: 1px solid black;
    border-radius: 10px;
}
.google img{
    width: 100%;
    height: 92%;
}