.app {
  text-align: center;
  height: 100%;
  min-height: 100vh;
  background-image: url("./assets/img/abs_site_background.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 1000px) {
  .app {
    background-position: -30px;
  }
}
@media (max-width: 900px) {
  .app {
    background-position: -100px;
  }
}
@media (max-width: 700px) {
  .app {
    background-position: -200px;
  }
}

